import React from 'react';
import SEO from 'react-seo-component';
import Layout from '~layouts/mainLayout';
import WelcomeSection from '~components/welcome';
import ProjectsSection from '~components/projects';
import MusicSection from '~components/music';
import BlogSection from '~components/blog';
import { useSiteMetadata } from '~hooks/useSiteMetadata';
import TestimonialsSection from '~components/testimonials';
import IntroCase from '~components/introCase';
import CaseBody from "~components/caseBody";
import cioffiLawLogo from "../assets/images/cioffilawfirm.png"
import { FaArrowRight } from 'react-icons/fa';
import clientWork1 from "../assets/images/clientwork1.png";
import Fade from "react-reveal/Fade";
export default function Home() {
  const {
    title,
    titleTemplate,
    description,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata();
  return (
    <>
      <SEO
        title={title}
        titleTemplate={titleTemplate}
        titleSeparator="|"
        description={description || 'Welcome to my’'}
        image={`${siteUrl}${image}`}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />
      <Layout>
        <IntroCase title="Cioffi Law Firm" subtitle="Redesign and transformation of a brand." />
        <section className="mb-24">
     
       
     

        <div class="flex flex-wrap text-center justify-center ">
        <Fade bottom delays={700}>
        <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-amber mb-3">
      Challenge
    </h2>
    <p className="text-secondary prose-lg mb-6">
   Cioffi Law Firm, a law firm based in North Carolina which is well succesful after 8 years and having settled and litigated hundreds. The company was on a journey to redesign and re-create the brand to be positioned differently than the competition. 
    </p>
    </Fade>
      </div>
    
      <div class="container p-4 mb-10 lg:20 lg:px-20 mx-auto text-center ">
		<div class="relative w-full my-4 mr-auto rounded-2xl ">
			<img alt="Card" src={clientWork1} class="max-w-full rounded-lg "/>
        </div>
	
                <div class="col-span-12 lg:col-span-8 text-center">
                        <a href="#" class="inline-block rounded-full text-white 
                            bg-red-400 hover:bg-red-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            Gatsby - GraphQL
                        </a>
                        <a href="#" class="inline-block rounded-full text-white 
                            bg-yellow-400 hover:bg-yellow-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            Strapi CMS / Backend
                        </a>
                        <a href="#" class="inline-block rounded-full text-white 
                            bg-green-400 hover:bg-green-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            Node-Sass
                        </a>
                        <a href="#" class="inline-block rounded-full text-white 
                            bg-blue-400 hover:bg-blue-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                            Typescript
                        </a>
                    </div>
  </div>
 

      <div class="flex flex-wrap text-center justify-center mb-20">
      <Fade top delays={700}>
        <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-green-500 mb-3">
      Solution
    </h2>
    <p className="text-secondary prose-lg mb-6">
I proposed to start from scratch as the majority of lawyer's website in North Carolina were using Wordpress with almost the same UX/UI as other sites. I chose Gatsby for the front-end and SaSS + Tailwind for the styling. I almost chose Next.js but Gatsby was selected as it generates static HTML in advance, which can be stored on CDNs throughout the globe to facilitate quicker access. It also provides the best ratio of GraphQL, React and react-router which provide a developer-friendly static site generator. 

<br />
<br />

The redesigned    <a href="https://cioffilawfirm.com" target="_blank"><strong>cioffilawfirm.com</strong></a> is modern, well designed in order to improve user experience, increase SEO and attract new clients.
</p>
</Fade>
      </div>
      <a href="https://cioffilawfirm.com" target="_blank">
              <button className="p-4 mt-2 items-center flex justify-center text-center md:w-1/2 w-full  mx-auto font-medium text-white transition duration-300 transform bg-black border-2 border-black cursor-pointer dark:bg-white dark:border-white dark:text-black rounded-xl hover:opacity-80">
              <span className="mr-2">Visit</span> <span><FaArrowRight /></span> 
              </button>
        </a>
    
    </section>
      </Layout>
    </>
  );
}
