import React from 'react';
import Visiualizer from './Three/Visualizer';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'gatsby';

const IntroCase = ({title, subtitle}) => (
  <>
  <div className="home__canva">
  <Visiualizer customStyle={true}/>
  </div>
  <section className="mb-24">
    <div className=" flex justify-between items-center relative">
      <div className=" w-full space-y-8 md:flex-row-reverse md:justify-between">
      <Link to="/" className="text-primary">
          <FaArrowLeft  color=""/>
     </Link>
        <div className="text-left">
          <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-teal-500 dark:text-teal-400 mb-3">
            CASE STUDY
          </h2>
          <h1 className="text-primary text-3xl sm:text-5xl lg:text-5xl leading-none font-extrabold tracking-tight mb-4">
           {title}
          </h1>
          <p className="text-secondary prose-lg mb-6">
         {subtitle} </p>
        </div>
      </div>
    </div>
    <div className="absolute bottom-0 left-1/2 text-5xl leading-none text-coolGray-500 dark:text-coolGray-400 " />
  </section>
  </>
);

export default IntroCase;
